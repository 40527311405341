/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';

// Core
import useContext from '@/client/context';

// Components
import { Button, Popover } from '@client/components';

/*----------------------------------
- DEPENDANCES
----------------------------------*/

/*----------------------------------
- DEPENDANCES
----------------------------------*/
export default () => {

    const { request } = useContext();

    const languageBtnState = React.useState(false);

    const translateUrl = (to: string) => () => {
        window.location.href = to === 'en' 
            ? 'https://becrosspath.com'
            : (
                'https://becrosspath-com.translate.goog' + request.path + 
                '?_x_tr_sl=en&_x_tr_tl=' + to + 
                '&_x_tr_hl=' + to + 
                '&_x_tr_pto=wapp&_x_tr_hist=true'
            )
    }

    return (
        <Popover state={languageBtnState} content={(
            <ul class="card col menu">
                <li>
                    <Button onClick={translateUrl('en')}>
                        English
                    </Button>
                </li>
                <li>
                    <Button onClick={translateUrl('fr')}>
                        French
                    </Button>
                </li>
                <li>
                    <Button onClick={translateUrl('de')}>
                        German
                    </Button>
                </li>
                <li>
                    <Button onClick={translateUrl('zh-CN')}>
                        Chinese
                    </Button>
                </li>
            </ul>
        )}>
            <Button type="secondary" size="s" shape='pill'>
                EN
            </Button>
        </Popover>
    )
}