/*----------------------------------
- DEPS
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';

// Resources
import QuoteImg from '@/client/assets/img/quote-top.png';

/*----------------------------------
- TYPES
----------------------------------*/

export type Props = {
    text: ComponentChild,
    person: string,
    logo?: string,
    description: ComponentChild,
    photo: string,
}

/*----------------------------------
- COMPONENT
----------------------------------*/
import './index.less';
export default ({ text, person, description, photo, logo, className }: Props) => {
    return (
        <figure class={"testimonial card col w-3-5 " + (className || '')}>
            <div class="row sp-btw">
                <img src={QuoteImg} />
                {logo && (
                    <img src={logo} style={{ maxWidth: '120px', maxHeight: '40px' }} />
                )}
            </div>
            <blockquote class="row-1">
                {text}
            </blockquote>
            <footer class="row">
                <img class="logo xl" src={photo} />
                <div class="col-1 col sp-0 al-left txt-left">
                    <cite class="h3 person">{person}</cite>
                    {description && (
                        <cite class="company">{description}</cite>
                    )}
                </div>
            </footer>
        </figure>
    )
}