/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';

// Core
import useHeader, { Props as useHeaderProps } from '@client/pages/useHeader';

// App
import Navigation from './Navigation';

// Resources
import LogoWithText from '@/client/assets/identity/logoAndText.png';

/*----------------------------------
- TYPES
----------------------------------*/

export type Props = { 
    children: ComponentChild,
    headerClass?: string,
    audience: 'headhunters'|'companies'
} & useHeaderProps

/*----------------------------------
- DEPENDANCES
----------------------------------*/
export default ({ children, headerClass, audience, ...useHeaderProps }: Props) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    useHeader(useHeaderProps);
    
    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return (
        <>
            <Navigation className={headerClass} audience={audience} />

            <main id="page" class="home col sp-0 al-fill">

                {children}
                
            </main>
        </>
    )
}