/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';

// Core
import { Router } from '@app';

// Core components
import { Button } from '@client/components';

/*----------------------------------
- TYPES
----------------------------------*/

export type TQnA = {
    question: string,
    answer: string
}

/*----------------------------------
- CONTROLEUR
----------------------------------*/
export default ({ QnA }: { QnA: TQnA[] }) => {

    /*----------------------------------
    - INIT
    ----------------------------------*/

    const [state, setState] = React.useState<{
        question: number | null
    }>({
        question: null
    });

    /*----------------------------------
    - ACTIONS
    ----------------------------------*/

    const openAnswer = (newAnswerIndex: number) => setState( curState => ({
        ...curState,
        question: newAnswerIndex === curState.question ? null : newAnswerIndex 
    }));

    /*----------------------------------
    - RENDER
    ----------------------------------*/
    return (
        <ul class="col w-4-6">
            {QnA.map(({ question, answer }, qIndex) => (
                <li class="card clickable col pd-1" 
                    onClick={() => openAnswer(qIndex)}>
                    <h3>{question}</h3>
                    {state.question === qIndex && (
                        <p>
                            {answer}
                        </p>
                    )}
                </li>
            ))}
        </ul>
    )
}