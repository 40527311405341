/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';

// Core components
import { Link } from '@client/services/router';
import { Button } from '@client/components';

// App Components
import TranslateButton from '@/client/components/TranslateButton';

// Resources
import "./index.less";
import LogoWithText from '@/client/assets/identity/logoAndText.svg';

/*----------------------------------
- COMPONENT
----------------------------------*/
export default ({ className, audience }: {
    className?: string,
    audience: 'headhunters'|'companies'
}) => {

    const [open, setOpen] = React.useState(false);

    return (
        <header id="header" class={(open ? 'menu-open' : '') + ' ' + (className || '')}>
            <div class="center row" id="topbar">
                
                <Link className="crosspath-logo" to="/" />
    
                <nav class='col-1 row al-right'>
                    <ul class="menu row s">
                        <li>
                            <Button nav="exact" link="/">Companies</Button>
                        </li>
                        <li>
                            <Button nav link="/headhunters">Headhunters</Button>
                        </li>
                        <li>
                            <Button link="https://crosspath.ghost.io/" target='_blank'>Blog</Button>
                        </li>
                    </ul>
                </nav>

                <div class="row btns">

                    <TranslateButton />

                    {audience === 'companies' ? (
                        <Button type="primary" shape='pill' size="s" link="/#bookDemo">
                            Book a Demo
                        </Button>
                    ) : <>
            
                        <Button type="primary" shape='pill' size="s" link="https://recruiters.becrosspath.com">
                            Login
                        </Button>
        
                        <Button type="primary" shape='pill' size="s" link="https://recruiters.becrosspath.com/auth/signup">
                            Signup
                        </Button>
                    </>}
                </div>

            </div>
            <div class="center row sp-btw" id="mobile-topbar">

                <Link id="logo" to="/"><img src={LogoWithText} /></Link>

                <div class="row">

                    {audience === 'companies' ? (
                        <Button type="primary" shape='pill' size="s" link="/#bookDemo">
                            Book a Demo
                        </Button>
                    ) : (
                        <Button type="primary" shape='pill' size="s" link="https://recruiters.becrosspath.com/">
                            Signup
                        </Button>
                    )}
        
                    <Button id="menuBtn" size="s" shape="pill" icon="bars" onClick={() => setOpen(o => !o)} />
                </div>

            </div>
        </header>
    )
}